<template>
  <page-layout>
    <span slot="header-name">{{ $tf('common.episodes') }}</span>
    <span slot="header-detail">
      {{ $tf('common.match,,1') }}: {{ stats.episodes.matched }}, {{ $tf('common.total') }}:
      {{ stats.episodes.total }}
    </span>
    <div slot="header-content-actions">
      <i class="el-icon-loading" v-if="state.playing && $store.state.server.connected"></i>
      <el-button name="play-btn" size="small" type="primary" v-if="!state.playing" @click="togglePlaying" :disabled="!!state.filter.current.page">
        <i class="fa fa-play"></i>
      </el-button>
      <el-button name="pause-btn" size="small" :plain="true" v-else @click="togglePlaying"><i class="fa fa-pause"></i></el-button>
    </div>

    <table-navigation slot="header-content-navigation" :state="state"></table-navigation>
    <div slot="content" v-loading="state.loading">
      <episode-item :key="'episode-' + item.id" :item="item" v-for="item in state.items"></episode-item>
    </div>
    <table-navigation slot="footer-content-navigation" :state="state"></table-navigation>
  </page-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import qs from 'qs';
import { Component, Vue } from 'vue-property-decorator';
import TableNavigation from '@/components/tables/navigation.vue';
import DossierListsInline from '../dossier-lists/inline.vue';
import ObjectsInline from '../objects/inline.vue';
import ObjectThumbnail from '../objects/thumbnail.vue';
import EpisodeItem from './item';
import PageLayout from '@/components/page/layout';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

@Component({
  components: {
    PageLayout,
    EpisodeItem,
    ObjectThumbnail,
    DossierListsInline,
    ObjectsInline,
    TableNavigation
  }
})
export default class PageEpisodes extends Vue {
  timeout = null;

  get model() {
    return 'episodes';
  }

  get state() {
    return this.$store.state.episodes;
  }

  get stats() {
    return this.$store.state.stats;
  }

  get camerasMap() {
    return computeItemsIdsMap(this.$store.state.cameras.items);
  }

  get cameraGroupsMap() {
    return computeItemsIdsMap(this.$store.state.camera_groups.items);
  }

  mounted() {
    this.$cache.enable(true);
    this.loadStatisticContinuously();
  }

  loadStatisticContinuously() {
    this.$store.dispatch('getStats', 'episodes').then(() => {
      this.timeout = setTimeout(this.loadStatisticContinuously, 10000);
    });
  }

  beforeDestroy() {
    this.$cache.enable(false);
    clearTimeout(this.timeout);
  }

  togglePlaying() {
    this.state.playing = !this.state.playing;
  }

  rowClick(item) {
    if (this.$store.state.app.key.ctrl) return;
    this.$router.push({ path: this.getItemLink(item) });
  }

  showImage(url, row) {
    const licensePlateBbox = row.features?.license_plate_number?.bbox;
    this.$store.dispatch('showImage', {
      src: url,
      faces_bbox: [this.getBox(row)],
      cars_bbox: licensePlateBbox ? [this.getLicensePlateBox(licensePlateBbox)] : null
    });
  }

  getLicensePlateBox({ left, top, right, bottom }) {
    const result = {
      x: left,
      y: top,
      w: right - left,
      h: bottom - top
    };
    return result;
  }
  getBox(event) {
    return {
      x: event.frame_coords_left,
      y: event.frame_coords_top,
      w: event.frame_coords_right - event.frame_coords_left,
      h: event.frame_coords_bottom - event.frame_coords_top
    };
  }

  getItemLink(item) {
    return '/episodes/' + encodeURIComponent(item.id) + '/';
  }

  getFilterPath(filter) {
    return '#/episodes/filter/' + qs.stringify(Object.assign(filter, { limit: 20 }), { sort: alphabeticalSort });
  }
}

function computeItemsIdsMap(items) {
  return items.reduce((itemsMap, item) => _.set(itemsMap, item.id, item), {});
}
</script>

<style lang="stylus">
.event-reaction-content {
  margin-top: 1rem;
}

.filter--page-limit {
  display: inline-block;
  width: 5rem;
}

.tag-loading {
  cursor: none !important;
  opacity: 0.2;
}
</style>
